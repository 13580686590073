import React from 'react';
import IndexLayout from '@layouts/index';
import Header from '@components/Header';
import AboutGraphic from '@assets/images/about.png';

import './about.scss';

// Image imports
import StavrosPapadopoulos from '@assets/images/about/stavros-papadopoulos.jpg';
import AbhishekSharma from '@assets/images/about/abhishek-sharma.png';
import SamuelMadden from '@assets/images/about/samuel-madden.png';
import MikeGoguen from '@assets/images/about/mike-goguen.jpg';
import JeffMiller from '@assets/images/about/jeff-miller.jpg';
import JorgeColindres from '@assets/images/about/jorge-colindres.jpg';

import TwoBearCapital from '@assets/images/about/investors/two_bear_capital_logo.png';
import Nexus from '@assets/images/about/investors/nexus_ventures_partners_logo.png';
import IntelCapital from '@assets/images/about/investors/intel_capital_logo.png';
import BigPI from '@assets/images/about/investors/BigPi_logo.png';
import Uncorrelated from '@assets/images/about/investors/uncorrelated_ventures_logo.png';
import NTTDocomo from '@assets/images/about/investors/ntt_docomo_logo.png';
import LockheedMartin from '@assets/images/about/investors/lockheed_martin_logo.png';
import AmgenVentures from '@assets/images/about/investors/amgen_ventures_logo.png';
import Verizon from '@assets/images/about/investors/verizon_logo.png';
import AlleyCorp from '@assets/images/about/investors/alley_corp_log.png';
import SVentures from '@assets/images/about/investors/sventures_logo.png';
import LDVPartners from '@assets/images/about/investors/ldv_partners_logo.png';
import ScaleAsiaVentures from '@assets/images/about/investors/scale_asia_ventures_logo.png';
import EndeavorCatalyst from '@assets/images/about/investors/endeavor_catalyst_logo.png';
import FormicVentures from '@assets/images/about/investors/formic_ventures_logo.png';

import Team from '@page-components/about/Team';
import Footer from '@components/Footer';
import PageHeader from '@components/PageHeader';
import Container from '@components/Container';
import ExtLink from '@components/ExtLink';
import MemberCard from '@components/MemberCard';
import Section from '@layouts/Section';

const investorLogos = [
  {
    logo: TwoBearCapital,
    alt: 'two bear capital',
    linkTo: 'https://twobearcapital.com/',
  },
  {
    logo: Nexus,
    alt: 'nexus',
    linkTo: 'https://nexusvp.com/',
  },
  {
    logo: AlleyCorp,
    alt: 'Alley Corp',
    linkTo: 'https://alleycorp.com/',
  },
  {
    logo: BigPI,
    alt: 'Big PI',
    linkTo: 'https://bigpi.vc/',
  },
  {
    logo: IntelCapital,
    alt: 'Intel Capital',
    linkTo: 'https://intelcapital.com',
  },
  {
    logo: LockheedMartin,
    alt: 'Lockheed Martin',
    linkTo: 'https://www.lockheedmartin.com/en-us/who-we-are/lockheed-martin-ventures.html',
  },
  {
    logo: AmgenVentures,
    alt: 'Amgen Ventures',
    linkTo: 'https://www.amgenbd.com/',
  },
  {
    logo: NTTDocomo,
    alt: 'NTT Docomo',
    linkTo: 'https://www.nttdocomo-v.com/en/',
  },
  {
    logo: Uncorrelated,
    alt: 'Uncorrelated',
    linkTo: 'https://uncorrelated.com/',
  },
  {
    logo: Verizon,
    alt: 'Verizon ventures',
    linkTo: 'https://www.verizon.com/about/our-company/verizon-ventures',
  },
  {
    logo: SVentures,
    alt: 'S Ventures',
    linkTo: 'https://www.sentinelone.com/lp/s-ventures/',
  },
  {
    logo: LDVPartners,
    alt: 'LDV Partners',
    linkTo: 'https://ldvp.com/',
  },
  {
    logo: ScaleAsiaVentures,
    alt: 'Scale Asia Ventures',
    linkTo: 'https://www.sav.vc/',
  },
  {
    logo: EndeavorCatalyst,
    alt: 'Endeavor Catalyst',
    linkTo: 'https://endeavor.org/catalyst/',
  },
  {
    logo: FormicVentures,
    alt: 'Formic Ventures',
    linkTo: 'https://formic.vc/',
  },
];

const About: React.FC = () => {
  return (
    <>
      <IndexLayout
        pageName="about"
        helmet={{
          title: 'About Us - TileDB',
          description: 'The modern database that streamlines all data modalities, code and compute in a single product.',
        }}
      >
        <Header />
        <PageHeader
          title="About TileDB"
          description={
            <span>
              <p>TileDB is the modern database that integrates all data modalities, code and compute in a single product. </p> <br />
              As a database system, TileDB adapts its internal structure to optimize advanced applications across life sciences, geospatial
              research, generative AI, and other data science domains. TileDB is architected around multi-dimensional arrays, a powerful
              structure that adapts to capture any data modality — from vectors, tables and images, to ML models, 3D point clouds and
              population genomics datasets. TileDB provides an ecosystem of open-source tools, whereas its commercial product, TIleDB Cloud,
              offers a secure platform for distributed computations, interactive analysis, collaboration and integrations with a range of
              TileDB vertical solutions.
              <br /> <p>TileDB was spun out of MIT and Intel Labs in May 2017 and is backed by numerous world class investors.</p>
            </span>
          }
          graphic={AboutGraphic}
        />
        <main className="main">
          <Container>
            <Section name="investors">
              <h2 className="section__title">Investors</h2>
              <div className="section__container">
                {investorLogos.map((logo) => (
                  <React.Fragment key={logo.linkTo}>
                    <figure>
                      <ExtLink href={logo.linkTo} target="_blank">
                        <img src={logo.logo} alt={logo.alt} />
                      </ExtLink>
                    </figure>
                  </React.Fragment>
                ))}
              </div>
            </Section>
            <Team />
            <Section name="board">
              <h2 className="section__title">Board</h2>
              <div className="section__container">
                <MemberCard
                  name="Stavros Papadopoulos"
                  title="Founder &amp; CEO, TileDB, Inc."
                  link="https://www.linkedin.com/in/stavrospap/"
                  image={StavrosPapadopoulos}
                  bio=""
                  onClick={() => {
                    return false;
                  }}
                />
                <MemberCard
                  name="Michael Goguen"
                  title="Managing Partner, Two Bear Capital"
                  link="https://www.linkedin.com/in/michael-goguen-23314/"
                  image={MikeGoguen}
                  bio=""
                  onClick={() => {
                    return false;
                  }}
                />
                <MemberCard
                  name="Abhishek Sharma"
                  title="Managing Director, Nexus Venture Partners"
                  link="https://www.linkedin.com/in/sharmaabhishek/"
                  image={AbhishekSharma}
                  bio=""
                  onClick={() => {
                    return false;
                  }}
                />
                <MemberCard
                  name="Jorge Colindres"
                  title="Partner, AlleyCorp"
                  link="https://www.linkedin.com/in/jcolindres/"
                  image={JorgeColindres}
                  bio=""
                  onClick={() => {
                    return false;
                  }}
                />
                <MemberCard
                  name="Samuel Madden"
                  title="Professor, MIT"
                  link="https://www.linkedin.com/in/samuel-madden-8ba0835/"
                  image={SamuelMadden}
                  bio=""
                  onClick={() => {
                    return false;
                  }}
                />
                <MemberCard
                  name="Jeff Miller"
                  title="Chief Revenue Officer"
                  link="https://www.linkedin.com/in/jeff-miller-0697ab6/"
                  image={JeffMiller}
                  bio=""
                  onClick={() => {
                    return false;
                  }}
                />
              </div>
            </Section>
          </Container>
        </main>
        <Footer />
      </IndexLayout>
    </>
  );
};

export default About;
